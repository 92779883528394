import * as React from "react"
import Layout from "../components/layout"
import JobPostsFilter from "../components/jobPostsFilter"
import Seo from "../components/seo"
import JobsNav from "../components/jobsNav"
import JobsNavChefs from "../components/jobsNavChefs"

/*

Categories:

chefs = 2
front-of-house = 9
sales-marketing = 11
operations = 13
international = 4 


*/

const Chefs = () => (
  <Layout
    jobPage={true}
    splashPage={false}
    pageTitle="VACANCIES - SENIOR CHEFS"
  >
    <Seo title="Vacancies senior chefs" />
    <JobsNav link={"chefs"} />
    <JobsNavChefs />
    <JobPostsFilter category={2} />
  </Layout>
)

export default Chefs
